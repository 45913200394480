<template>
  <b-container>
    <CompetionNotStarted />

    <h2 class="d-flex align-items-center mb-2 py-2">
      <b-icon icon="file-binary" class="mr-2"></b-icon>Punten ranglijst
    </h2>

    <transition name="fade">
      <div v-if="usersSortByPoints.length">
        <b-card
          v-for="(user, index) in usersSortByPoints"
          :key="index"
          bg-variant="dark"
          :header-class="'ranking-' + ranking(user, index)"
          header-bg-variant="secondary"
          class="mb-2"
        >
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b
                  class="badge badge-transparent mr-1"
                  v-if="!competitionOnHold"
                  >#{{ index + 1 }}</b
                >
                <b class="ml-1">{{ user.name }}</b>
              </div>
              <div>
                <b-icon
                  v-if="index === 0 && !competitionOnHold"
                  icon="trophy-fill"
                  class="ml-2"
                >
                </b-icon>
                <b-icon
                  v-if="user.id === userProfile.id"
                  icon="person-fill"
                  class="ml-2"
                >
                </b-icon>
              </div>
            </div>
          </template>
          <b-card-text>
            <p>
              <small>
                Totale punten: <b>{{ user.totalPoints }}</b>
              </small>
            </p>
            <small>
              Aantal wedstrijd gespeeld: <b> {{ gamesPlayed(user) }}</b>
            </small>
          </b-card-text>
          <template #footer class="justify-content-between">
            <div class="d-flex justify-content-between align-items-center">
              <small class="text-muted">
                Ladderrating: {{ user.ladderRating }}
              </small>
            </div>
          </template>
        </b-card>
      </div>
    </transition>

    <div v-if="!usersSortByPoints.length && !loader">
      <p class="no-results">Er zijn op het moment geen gebruikers</p>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import AddGameModal from '@/components/AddGameModal'
import CompetionNotStarted from '@/components/CompetionNotStarted'

export default {
  computed: {
    ...mapState([
      'competitionOnHold',
      'userProfile',
      'games',
      'usersSortByPoints'
    ]),
    newGame() {
      // check if there's a new game for you
      let gameToAccept = this.games.filter(game => {
        if (game.pending && game.contester.id === this.userProfile.id) {
          return game
        }
      })

      return gameToAccept.length > 0
    }
  },
  methods: {
    isProfile(user) {
      return this.userProfile.id === user.id
    },

    isProfileVariant(user) {
      return this.isProfile(user) ? 'light' : 'secondary'
    },

    ranking(user, index) {
      console.log(user.totalPoints, this.gamesPlayed(user)) // fix de gedeelde stand
      if (this.competitionOnHold) {
        return 'grey'
      } else {
        if (index === 0) return 'gold'
        if (index === 1) return 'silver'
        if (index === 2) return 'bronze'
        if (index >= 3) return 'grey'
      }
      return 'grey'
    },

    gamesPlayed(user) {
      console.log(user.name)
      // check if there's a new game for you
      let gamesPlayed = this.games.filter(game => {
        if (
          (game.contester.id === user.id || game.challenger.id === user.id) &&
          !game.pending
        ) {
          return game
        }
      })

      return gamesPlayed.length
    }
  },
  components: {
    AddGameModal,
    CompetionNotStarted
  }
}
</script>

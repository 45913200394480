<template>
  <b-container>
    <div class="d-flex justify-content-between align-items-center mb-2 py-2">
      <h2 class="d-flex align-items-center">
        <b-icon icon="people" class="mr-2"></b-icon> Games
      </h2>

      <b-button
        size="sm"
        variant="primary"
        @click="myGamesToggled = !myGamesToggled"
      >
        mijn games
        <b-icon
          v-if="!myGamesToggled"
          icon="circle"
          aria-hidden="true"
          class="ml-1"
        ></b-icon>
        <b-icon
          v-if="myGamesToggled"
          icon="check-circle-fill"
          aria-hidden="true"
          class="ml-1"
        ></b-icon>
      </b-button>
    </div>
    <b-card
      v-for="(game, index) in filteredGames"
      :key="index"
      :bg-variant="isPendingVariant(game)"
      :border-variant="isProfileVariant(game)"
      :header-bg-variant="isProfileVariant(game)"
      :footer-text-variant="isPendingVariantFooterTxt(game)"
      :footer-bg-variant="isPendingVariantFooterBg(game)"
      class="mb-3"
    >
      <template #header>
        <div
          class="d-sm-flex justify-content-between align-items-center text-center text-sm-left"
        >
          <div>
            <small class="badge badge-transparent mr-2 align-middle"
              >#{{ game.challenger.ladderRating }}</small
            ><b>{{ game.challenger.name }}</b>
          </div>
          <span>-</span>
          <div>
            <b>{{ game.contester.name }}</b>
            <small class="badge badge-transparent ml-2 align-middle">
              #{{ game.contester.ladderRating }}
            </small>
          </div>
        </div>
      </template>
      <b-card-text>
        <small>
          <b>Uitslag:</b> {{ game.challenger.setsWon }} -
          {{ game.contester.setsWon }}<br /><br />
          <b>Winnaar:</b> {{ game.winner }}
        </small>
      </b-card-text>

      <template v-if="canEdit(game) && game.pending">
        <b-button variant="primary" class="mr-1" @click="acceptGame(game)">
          <b-icon icon="check" aria-hidden="true"></b-icon> Goedkeuren
        </b-button>

        <b-button variant="dark" @click="deleteGame(game)">
          <b-icon icon="trash" aria-hidden="true"></b-icon> Verwijderen
        </b-button>
      </template>

      <template #footer>
        <div class="d-flex justify-content-between align-items-center">
          <small :class="{ 'text-muted': !game.pending }">
            Wedstrijd gespeeld: {{ game.createdOn | formatDate }}
          </small>

          <div>
            <b-icon
              v-if="!game.pending"
              icon="check-square-fill"
              variant="success"
              class="mr-1"
            ></b-icon>
            <div class="d-flex align-items-center" v-else>
              <small>Nog niet gekeurd</small>
              <b-icon
                icon="exclamation-circle-fill"
                class="ml-1"
                variant="warning"
              ></b-icon>
            </div>
          </div>
        </div>
      </template>
    </b-card>
    <div v-if="games.length < 1 && !competitionOnHold">
      Er zijn op het moment nog geen wedstrijden gespeeld
    </div>
    <div v-if="competitionOnHold">
      De compititie moet nog beginnen, nog even geduld
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import * as fb from '../firebase'
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

export default {
  data() {
    return {
      basePointsGiven: 1,
      myGamesToggled: false
    }
  },
  computed: {
    ...mapState([
      'competitionOnHold',
      'userProfile',
      'games',
      'users',
      'adm1n'
    ]),
    filteredGames() {
      // check if there's a new game for you
      let myGames = this.games.filter(game => {
        if (
          this.myGamesToggled &&
          (game.challenger.id === this.userProfile.id ||
            game.contester.id === this.userProfile.id)
        ) {
          return game
        }
      })

      return myGames.length > 0 ? myGames : this.games
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return '-'
      }

      let date = val.toDate()
      return moment(date).fromNow()
    }
  },
  methods: {
    toggledIcon() {
      return this.myGamesToggled ? 'check-circle-fill' : 'circle-fill'
    },
    isProfile(game) {
      return (
        this.userProfile.id === game.contester.id ||
        this.userProfile.id === game.challenger.id
      )
    },
    isProfileVariant(game) {
      return this.isProfile(game) ? 'primary' : 'secondary'
    },
    isPendingVariant(game) {
      return game.pending ? 'secondary' : 'dark'
    },
    isPendingVariantFooterTxt(game) {
      return game.pending ? 'light' : 'dark'
    },
    isPendingVariantFooterBg(game) {
      return game.pending ? 'secondary' : 'dark'
    },
    canEdit(game) {
      // state bijhouden in de store die zegt dat er een wedstrijd klaar staat om geaccepteerd te worden
      return (
        this.userProfile.id === game.contester.id ||
        this.userProfile.id === this.adm1n
      )
    },

    async deleteGame(game) {
      await fb.gamesCollection.doc(game.id).delete()
    },

    async acceptGame(game) {
      await fb.gamesCollection.doc(game.id).update({
        pending: false
      })

      await fb.usersCollection.doc(game.challenger.id).update({
        lastGameId: game.id
      })

      await fb.usersCollection.doc(game.contester.id).update({
        lastGameId: game.id
      })

      this.updateRatingAndPoints(game)
    },
    async updateRatingAndPoints(game) {
      // Get the profile from the challenger
      let getChallengerProfile = this.users.find(function(user) {
        return user.id === game.challenger.id
      })

      // Get the profile from the contester
      let getContesterProfile = this.users.find(function(user) {
        return user.id === game.contester.id
      })

      // set the sets won by the challenger
      const setsChallengerWon = game.challenger.setsWon + this.basePointsGiven

      // set the sets won by the contester
      const setsContesterWon = game.contester.setsWon + this.basePointsGiven

      // Set the rating and points when game is accepted
      if (game.winner === game.challenger.name) {
        await fb.usersCollection.doc(game.challenger.id).update({
          ladderRating: game.contester.ladderRating,
          totalPoints: getChallengerProfile.totalPoints + setsChallengerWon
        })
        await fb.usersCollection.doc(game.contester.id).update({
          ladderRating: game.challenger.ladderRating,
          totalPoints: getContesterProfile.totalPoints + setsContesterWon
        })
      } else {
        await fb.usersCollection.doc(game.challenger.id).update({
          totalPoints: getChallengerProfile.totalPoints + setsChallengerWon
        })

        await fb.usersCollection.doc(game.contester.id).update({
          totalPoints: getContesterProfile.totalPoints + setsContesterWon
        })
      }
    }
  }
}
</script>

<template>
  <b-container>
    <h2 class="d-flex align-items-center mb-2 py-2">
      <b-icon icon="person" class="mr-2"></b-icon> Profiel
    </h2>
    <div class="p-3 mb-3 bg-dark text-white">
      <h3>{{ userProfile.name }}</h3>
      <hr class="border-light" />

      <p><b>NTTB rating:</b> {{ userProfile.nttbRating }}<br /></p>
      <hr class="border-secondary" />
      <p>
        <b>Ladder rating:</b>
        <span class="badge badge-warning ml-2">
          {{ userProfile.ladderRating }}
        </span>
        <br />
        <b>Totale punten:</b>
        <span class="badge badge-warning ml-2">
          {{ userProfile.totalPoints }}
        </span>
      </p>
      <hr class="border-secondary" />
      <p>
        <b>Wedstrijden gespeeld:</b>
        <span class="badge badge-warning ml-2">
          {{ gamesPlayed }}
        </span>
        <br />
        <b>Wedstrijden gewonnen:</b>
        <span class="badge badge-warning ml-2">
          {{ gamesWon }}
        </span>
        <br />
      </p>
      <hr class="border-secondary" />
      <p>
        <b>Aangemeld sinds:</b> {{ userProfile.creationTime | formatDate
        }}<br />
      </p>
    </div>
    <b-button variant="primary" @click="logout">
      Uitloggen
    </b-button>
    <div class="p-3 bg-dark mt-3" v-if="isAdmin && pendingUsers.length">
      <h4>Nieuwe gebruikers:</h4>
      <hr />
      <div
        v-for="(user, index) in pendingUsers"
        :key="index"
        class="bg-darkgrey p-3 mb-2"
      >
        <b>{{ user.name }}</b>
        <div class="mt-2">
          <b-button @click="deleteUser(user)" class="float-right"
            >verwijderen</b-button
          >
          <b-button variant="primary" class="mr-1" @click="acceptUser(user)">
            Accepteren
          </b-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import * as fb from '../firebase'

import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

export default {
  data() {
    return {}
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return '-'
      }

      return moment(val).fromNow()
    }
  },
  computed: {
    ...mapState(['userProfile', 'users', 'adm1n', 'games']),
    pendingUsers() {
      let pendingUsers = this.users.filter(user => {
        if (!user.approved) {
          return user
        }
      })
      return pendingUsers
    },
    isAdmin() {
      return this.userProfile.id === this.adm1n
    },
    gamesPlayed() {
      // check if there's a new game for you
      let gamesPlayed = this.games.filter(game => {
        if (
          (game.contester.id === this.userProfile.id ||
            game.challenger.id === this.userProfile.id) &&
          !game.pending
        ) {
          console.log(game)
          return game
        }
      })

      return gamesPlayed.length
    },

    gamesWon() {
      const gamesWon = this.games.filter(game => {
        if (game.winner === this.userProfile.name) {
          return game
        }
      })

      return gamesWon.length
    }
  },
  methods: {
    async acceptUser(user) {
      await fb.usersCollection.doc(user.id).update({
        approved: true
      })
    },
    async deleteUser(user) {
      await fb.usersCollection.doc(user.id).delete()

      window.open(
        'https://console.firebase.google.com/project/laddercompetitie-57af8/authentication/users'
      )
    },
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
.border {
  border: 1px solid black;
  padding: 1em;
}
</style>
